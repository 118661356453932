<template>
  <section class="p-6">
            <ul class="grid grid-cols-1 sm:grid-cols-3 gap-8 mb-8">
                <li v-for="item in years" :key="item.id" @click="passeYear(item.id)">
                <a :class="dark ? 'bg-gray-700 border-none text-white' : 'shadow-years'" class="group hover:bg-red-400 cursor-pointer block rounded-lg p-4 border border-gray-200" >
                    <dl class="grid sm:block lg:grid xl:block grid-cols-1 sm:grid-rows-1 justify-center items-center">
                    <div>
                        <dt class="flex flex-row justify-between ">
                            <div class="flex flex-row"> 
                                <div v-for="i in item.id" :key="i" :class="`w-4 h-4 rounded-full ${colors[i-1]} mb-2 m-1`"></div>
                            </div>
                            <div  class="flex flex-row">
                                <el-popover :width="400" trigger="click">
                                    <template #reference>
                                        <button class="py-1 px-2">
                                            <el-icon :size="25">
                                                <More />
                                            </el-icon>
                                        </button>
                                    </template>
                                    <div class="mb-2">
                                        <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
                                    </div>
                                    <el-scrollbar height="146px" class="w-full border rounded-md bg-white">
                                        <w-list v-model="selectionm" :items="item.modules"
                                         :multiple=false 
                                         @item-click="passeModule($event)"
                                            class="mt6 mr4 grow">
                                        </w-list>
                                    </el-scrollbar>
                                </el-popover>
                            </div>
                        </dt>
                        <dd class="leading-6 font-medium">
                            {{item.title}}
                        </dd>
                    </div>
                    <div>
                        <dd class="flex flex-row justify-center group-hover:text-light-blue-200 text-center p-5 font-medium sm:mb-4 lg:mb-0 xl:mb-4">
                            <p class="text-5xl mx-2"> {{ item.id }} </p>
                            <p class="ordinal">année</p>
                        </dd>
                    </div>
                    </dl>
                </a>
                </li>
            </ul>
            <ul class="grid grid-cols-1 sm:grid-cols-1 gap-4">
                <li @click="passeYear('7')">
                <a :class="dark ? 'bg-gray-700 border-none text-white' : 'shadow-years'" class=" hover:bg-red-400 cursor-pointer block rounded-lg p-4 border border-gray-200" >
                    <dl class="grid sm:block lg:grid xl:block grid-cols-1 sm:grid-rows-1 sm:py-4 justify-center items-center">
                    <div>
                        <dt class="sr-only">Title</dt>
                        <dd class="leading-6 font-medium ">
                            
                        </dd>
                    </div>
                    <div >
                        <dd class="flex flex-row justify-between items-center group-hover:text-light-blue-200 text-center font-medium mb-0">
                            <div class="flex flex-col">
                                <div v-for="i in 6" :key="i" class="flex flex-row">
                                    <div v-for="j in i" :key="j" :class="`w-4 h-4 rounded-full ${colors[j-1]} m-1`"></div>
                                </div> 
                            </div>
                            
                            <div>
                                <p class="leading-6 font-medium">Toutes les années</p>
                                <p class="text-4xl mx-2"> Résidanat </p>
                            </div>
                            <div class="flex flex-col opacity-0">
                                <div v-for="i in 6" :key="i" class="flex flex-row">
                                    <div v-for="j in i" :key="j" :class="`w-4 h-4 rounded-full ${colors[j-1]} m-1`"></div>
                                </div> 
                            </div>
                        </dd>
                    </div>
                    </dl>
                </a>
                </li>
            </ul>
        </section>
</template>

<script>
import { More } from '@element-plus/icons';
import axiosIns from '../../plugins/axios';
export default {
    components: {
        More,
    },
  data () {
     return {
        selectionm : null,
        currentRoute: window.location.pathname,
        isLoading: false,
        route:null,
        yearstudy: null,
        search: '',
        subscriptionsList: [],
        // selectedYear: '',
        years:[
            {
                id: 1,
                title: 'Première Année',
                modules: [],
            },
            {
                id: 2,
                title: 'Deuxième Année',
                modules: [],
            },
            {
                id: 3,
                title: 'Troisième Année',
                modules: [],
            },
            {
                id: 4,
                title: 'Quatrième Année',
                modules: [],
            },
            {
                id: 5,
                title: 'Cinquième Année', 
                modules: [],
            },
            {
                id: 6,
                title: 'Sixième Année',
                modules: [],
            },
        ],
        colors : ["blue","red","green","yellow","purple","pink"]
     }
   },

   props:{
        linkPath:Function,
        dark: Boolean
    },


    mounted () {
        this.linkPath(this.currentRoute);
        this.subscriptionsList = this.$store.getters.get_subscriptions
        
        this.years.forEach(year => {
            axiosIns
                .get("/moduleofyear/" + year.id)
                .then(({ data }) => {
                    let empty = []
                    data.modules.forEach(element => {
                        
                            const item = {
                                label: element.title,
                                value: element.id
                            }
                            empty.push(item);
                                   })
                    year.modules = empty
                })
                .catch(() => {
                    
                });
        })
    },

    methods : {
        passeYear(year){
                this.$router.push("/dashboard/subscriptionsinfo/?year=" + year);
        },
        passeModule(event) {
            this.$router.push("/dashboard/subscriptionsinfo/?module=" + event.value);
        },

        logoutsp() {
            this.$store.dispatch("logoutAction").then(() => {
            this.$router.push("/login");
            this.$store.commit('set_user', null);
            }).catch(() => {});
        },
    },

    computed:{
        User() {
            return this.$store.getters.get_user;
        },
        isToken() {
            return this.$store.getters.get_token;
        },
        startLoading() {
            this.isLoading = true;
        },

        endLoading() {
          this.isLoading = true;
        },
    }
}
</script>

<style>
.shadow-years{
    box-shadow:20px 5px 20px #f3f4f6,0px 5px 20px #f3f4f6,-20px 5px 20px #f3f4f6;
}

</style>